import Webcam from "react-webcam";
import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactPlayer from 'react-player'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';


const audioConstraints = {
    noiseSuppression: true,
    echoCancellation: true,
    autoGainControl: true,
  };

export function WebcamStreamCapture() {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [recordedVideos, setRecordedVideos] = useState([]);

    const [currVideo, setCurrVideo] = useState(0);

    const [onPreview, setOnPreview] = useState(false);
    const [showingPreview, showPreview] = useState(null);

    const [facingMode, setFacingMode] = useState("environment");

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    
      // Update the window dimensions on resize
      useEffect(() => {
        const handleResize = () => {
          setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    let ratio = 9/16;

    const [videoConstraints, setVideoConstraints] = useState({
        // aspectRatio: ratio,
        facingMode: "environment",
        width: windowDimensions.height,
        height: windowDimensions.width,
    });

    const handleFlipCameraToUser = React.useCallback((e) => {
        
        if (e.detail !== 2) {
            return
        }

        setVideoConstraints(
            {
                // aspectRatio: ratio,
                facingMode: "user",
                width: windowDimensions.height,
                height: windowDimensions.width,
            }
        )
        setFacingMode('user')
    }, [setFacingMode, setVideoConstraints]);

    const handleFlipCameraToEnv = React.useCallback((e) => {
        if (e.detail !== 2) {
            return
        }

        setVideoConstraints(
            {
                // aspectRatio: ratio,
                facingMode: "environment",
                width: windowDimensions.height,
                height: windowDimensions.width,
            }
        )
        setFacingMode('environment')
    }, [setFacingMode, setVideoConstraints]);

    const handleStartCaptureClick = React.useCallback(() => {
      console.log("RECORDING...")
      setRecordedChunks([]);
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/mp4"
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );

      mediaRecorderRef.current.endOfStream = (error) => {
        console.log("STREAM ENDED...",)

      }

      mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);
  
    const handleDataAvailable = React.useCallback(
      ({ data }) => {
        if (data.size > 0) {
          console.log("WRITING...", data)
          setRecordedChunks((prev) => prev.concat(data));

          console.log("CHUNKS", recordedChunks)
          const blob = new Blob([data], {
            type: "video/mp4"
          });
          // const file = new File([blob], "name.mp4");
          console.log(blob)
          setRecordedVideos((prev) => prev.concat(URL.createObjectURL(blob)));
        }
        // if (recordedChunks.length) {
        //     const blob = new Blob(recordedChunks, {
        //       type: "video/mp4"
        //     });
        //     // const file = new File([blob], "name.mp4");
        //     console.log(blob)
        //     setRecordedVideos((prev) => prev.concat(URL.createObjectURL(blob)));
        //  }
      },
      [setRecordedChunks, setRecordedVideos, recordedChunks]
    );
  
    const handleStopCaptureClick = React.useCallback(() => {
      console.log("STOPPED...")
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing, setRecordedVideos]);
  
    const handleDownload = React.useCallback(async() => {
        let videoURL = await stitchMP4s(recordedVideos)

        const a = document.createElement("a");
        document.body.appendChild(a);
        
        a.href = videoURL;
        a.download = "stitched-output.mp4";
        a.click();
        window.URL.revokeObjectURL(videoURL);
    }, [recordedVideos]);

    const handleProcessed = React.useCallback(async() => {
        console.log("PROCESSING...")
        let videoURL = await stitchMP4s(recordedVideos)
        let blob = await fetch(videoURL).then(r => r.blob());
        let nparts = videoURL.split("/")
        let finame = nparts[nparts.length - 1]+".mp4"
        
        let outputURL = await convertToMP4(new File([blob], finame), "processed.mp4")

        console.log("outputURL", outputURL)
        const a = document.createElement("a");
        document.body.appendChild(a);
        
        a.href = outputURL;
        a.download = "contest-submission.mp4";
        a.click();
        window.URL.revokeObjectURL(outputURL);

    }, [recordedVideos]);

    const ffmpeg = createFFmpeg({
        log: true
    })

  const [videoFileURL, setVideoFileURL] = useState(null);

  const stitchMP4s = async (videos: string[]) => {
    if (!ffmpeg.isLoaded()) {
        await ffmpeg.load();
    }

    var data = ""

    for (let i = 0; i < videos.length; i++) {
        let blob = await fetch(videos[i]).then(r => r.blob());
        let nparts = videos[i].split("/")
        let finame = nparts[nparts.length - 1]+".mp4"
        ffmpeg.FS('writeFile', finame, await fetchFile(blob))
        data += `file ${finame}\n`
    }
    const blob = new Blob([data], { type: "text/plain" });
    ffmpeg.FS('writeFile', 'videos.txt', await fetchFile(blob))
    // ffmpeg -f concat -safe 0 -i mylist.txt -c copy output.mp4
    let output = 'output.mp4'
    await ffmpeg.run("-f", 'concat', '-safe', '0', '-i', 'videos.txt', '-c', 'copy', output);
    const outputData = ffmpeg.FS('readFile', output);
    const videoURL =  URL.createObjectURL(new Blob([outputData.buffer], { type: "video/mp4" }))
    console.log("Stitched Video URL", videoURL)
    return videoURL
  }

  const convertToMP4 = async (videoFile: File, output: string) => {
    if (!ffmpeg.isLoaded()) {
        await ffmpeg.load();
    }

    console.log(videoFile)
    ffmpeg.FS('writeFile', videoFile.name, await fetchFile(videoFile))
    await ffmpeg.run("-i", videoFile.name, "-filter:v", "scale=576:1024,fps=30", "-movflags", "+faststart", "-vcodec", "libx264", "-preset", "ultrafast", output);
    const data = ffmpeg.FS('readFile', output);
    const videoURL =  URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }))
    return videoURL
  }

    const handlePreview = React.useCallback(async () => {
        console.log("PREVIEW...", recordedVideos.length)
        if (recordedVideos.length) {

        console.log("PROCESSING...")
        console.log(recordedVideos)
        showPreview(recordedVideos)
        setOnPreview(!onPreview)
        }
      }, [convertToMP4, recordedVideos, showPreview]);
  

    return (
      <div className="preview" onClick={facingMode === 'environment' ? handleFlipCameraToUser : handleFlipCameraToEnv}>

        <div className="button-overlay">
            {/* {
                facingMode === 'environment'
                ? <button onClick={handleFlipCameraToUser}>Flip To Front</button>
                : <button onClick={handleFlipCameraToEnv}>Flip To Back</button>    
            } */}

            {
                capturing ? (
                <button onClick={handleStopCaptureClick}>Stop Capture</button>
                ) : (
                <button onClick={handleStartCaptureClick}>Start Capture</button>
                )
            }

            {recordedVideos.length > 0 && (
                !onPreview ? (<button onClick={handlePreview}>Preview</button>)
                : (<button onClick={handlePreview}>Back</button>)
            )}

            {recordedVideos.length > 0 && (
                <button onClick={handleDownload}>Download</button>
            )}

            {recordedVideos.length > 0 && (
                <button onClick={handleProcessed}>Processed</button>
            )}
        </div>

        {/* <div className="button-overlay"> */}
            { 
                onPreview && (
                    <ReactPlayer
                        className='react-player'
                        url={showingPreview[currVideo]}
                        width={"100%"}
                        height={"100%"}
                        muted={true}
                        controls={true}
                        playing={true}
                        playsinline={true}
                        volume={1}
                        onEnded={
                            ()=>{
                                setCurrVideo((prev) => (prev + 1)%showingPreview.length)
                            } 
                        }
                    />
                )
            }
        {/* </div> */}

        <Webcam 
                width={"100%"}
                height={"100%"}
                muted={true}
                audio={true}
                imageSmoothing={true}
                mirrored={facingMode === 'user'}
                videoConstraints={videoConstraints}
                audioConstraints={audioConstraints}
                ref={webcamRef} />
      </div>
    );
  };
    
  // https://www.npmjs.com/package/react-webcam
  